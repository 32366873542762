import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import { Pagination, Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ agreed.js | bash.js | hyra.js | naxxa.js | oneclickapp.js |
//                          paramount-acceptance.js | purposeFinder.js | rakuten.js | renovation.js | wedax.js |
//                         ]

SwiperCore.use([Autoplay]);
const ResultsComponent = props => {
  const {
    resultsTitle,
    resultsDescription,
    projectFirstImage,
    projectSecondImage,
    projectThirdImage,
    projectFifthImage,
    projectForthImage,
    projectSixthImage,
    isAgreed,
    projectSeventhImage,
    isOtherProject,
  } = props;
  return (
    <div className='results-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <h4
            className='results-section-title'
            dangerouslySetInnerHTML={{ __html: resultsTitle }}
          />
        </div>
        <div className='results-section-text'>
          <p
            className='results-section-description'
            dangerouslySetInnerHTML={{
              __html: resultsDescription,
            }}
          ></p>
        </div>
        <Swiper
          pagination={{
            type: 'progressbar',
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mySwiper'
          spaceBetween={50}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          slidesPerView={1}
        >
          <SwiperSlide>
            <div className='projects-section-wrapper'>
              <div className=' projects-section-colImage'>
                <GatsbyImage
                  alt='projectFirst'
                  image={projectFirstImage}
                  className='projects-section-image'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='projects-section-wrapper'>
              <div className=' projects-section-colImage'>
                <GatsbyImage
                  alt='projectSecond'
                  image={projectSecondImage}
                  className='projects-section-image'
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='projects-section-wrapper'>
              <div className='projects-section-colImage'>
                <GatsbyImage
                  alt='projectThird'
                  image={projectThirdImage}
                  className='projects-section-image'
                />
              </div>
            </div>
          </SwiperSlide>
          {isOtherProject && (
            <div>
              <SwiperSlide>
                <div className='projects-section-wrapper'>
                  <div className='projects-section-colImage'>
                    <GatsbyImage
                      alt='projectForth'
                      image={projectForthImage}
                      className='projects-section-image'
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projects-section-wrapper'>
                  <div className='projects-section-colImage'>
                    <GatsbyImage
                      alt='projectFifth'
                      image={projectFifthImage}
                      className='projects-section-image'
                    />
                  </div>
                </div>
              </SwiperSlide>
            </div>
          )}
          {isAgreed && (
            <div>
              <SwiperSlide>
                <div className='projects-section-wrapper'>
                  <div className='projects-section-colImage'>
                    <GatsbyImage
                      alt='projectSix'
                      image={projectSixthImage}
                      className='projects-section-image'
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projects-section-wrapper'>
                  <div className='projects-section-colImage'>
                    <GatsbyImage
                      alt='projectForth'
                      image={projectForthImage}
                      className='projects-section-image'
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='projects-section-wrapper'>
                  <div className='projects-section-colImage'>
                    <GatsbyImage
                      alt='projectSeventh'
                      image={projectSeventhImage}
                      className='projects-section-image'
                    />
                  </div>
                </div>
              </SwiperSlide>
            </div>
          )}
        </Swiper>
      </div>
    </div>
  );
};
export default ResultsComponent;
